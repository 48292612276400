import { createGlobalStyle } from 'styled-components';

const classesPageStyles = createGlobalStyle`
#tab-classes-headline {
    padding-bottom: 0;
}

#classes-find-new-ways {
    padding-bottom: 0;
}
`;

export default classesPageStyles;
