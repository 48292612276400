import { black } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const newWayInStyleOverrides = createGlobalStyle`
    #clubvaluepropscolumns  p,
    #colorado-column  p,
    #florida-column  p,
    #georgia-column  p,
    #illinois-column  p,
    #maryland-column  p,
    #massachusetts-column  p,
    #minnesota-column  p,
    #texas-column  p,
    #virginia-column  p {
        color: ${black};
    }
`;
export default newWayInStyleOverrides;
