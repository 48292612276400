import { spacing, grey, white, red, secondaryFamily } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';
import { media } from '@peloton/styles';

const AFFIRM_COLOR = '#4A4AF4';
const CITIZENS_COLOR = '#008555';
const ZIP_COLOR = '#AA8FFF';

const financingPageStyles = createGlobalStyle`
#financing-dual-partner-pay-over-time {
  h1 {
    font-size: 18px;
    font-weight: 900;
    font-family: ${secondaryFamily};
    line-height: ${spacing[24]};
    letter-spacing: 1px;
    margin-bottom: 0;
  }

${media.tablet`
  h1 {
    font-size: ${spacing[24]};
    line-height: 32px;
    letter-spacing: 1px;
    min-height: 68px;
  }
`}

${media.desktopLarge`
  h1 {
  margin-bottom: ${spacing[24]};
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 1.5px;
  min-height: auto;
  }
`}

  p {
    font-size: 14px;
    line-height: ${spacing[24]};
    letter-spacing: 0.3px;
    min-height: ${spacing[48]};
  }

  ${media.tablet`
    p {
        font-size: 16px;
        min-height: auto;
    }
  `}

  div > div > div {
    flex-direction: column;

    ${media.tablet`
      flex-direction: row;
    `}
  }

  div > div > div > div {
    border-radius: ${spacing[16]} ${spacing[16]} 0px 0px;

    & > div:nth-child(2)[class^='Container'] {
     padding-top: ${spacing[48]};
     padding-bottom: 46px;

      div:first-child{
       flex-direction: column;
      }

      div {
       align-items: center;
       justify-content: center;
       text-align: center;
      }
    } 
  }
}

#financing-citizens-pay-hero {
    background-color: ${white};
     
    & > div > div {
      background: ${CITIZENS_COLOR};
    }

    div.citizens-financing-widget a {
      color: ${white} !important;

      &:hover {
        color: ${grey[90]} !important;
      }
    }

    span[class^='Button']:hover {
      a {
        color: ${grey[90]} !important;
      }
    }
  }

  #financing-affirm-hero {
    background-color: ${white};

    & > div > div {
      background: ${AFFIRM_COLOR};
    }
  }

  section[id^='financing-citizens-pay-tabs-item'] {
    span[class^='Button']:hover {
      a {
        color: ${white} !important;
      }
    }
  }

  #financing-zip-hero {
    background-color: ${white}; 

    & > div > div {
      background: ${ZIP_COLOR};
    }

    div.citizens-financing-widget a {
      color: ${white} !important;

      &:hover {
        color: ${grey[90]} !important;
      }
    }

    span[class^='Button']:hover {
      a {
        color: ${grey[90]} !important;
      }
    }
  }

  #financing-klarna-hero {
    background-color: ${white};
  }

  #financing-citizens-pay-partner-logo,
  #financing-affirm-partner-logo,
  #financing-with-partner-logo-non-us {
    padding-block: ${spacing[40]} 0;

    & > div > div {
      flex-direction: column;
      align-items: center;

      & > div {
        width: 268px;
        & > div {
          margin-bottom: ${spacing[16]};
          height: 32px;

          ${media.tablet`
            margin-bottom: 0;
          `}

           & > picture > img {
            height: 32px;
            object-fit: contain;
          } 
        }

        ${media.tablet`
          width: 390px;
        `}
      }

      & > section > div {
        text-align: center;
        max-width: 326px;
        padding: 0;
        & > h1 {
          font-size: 26px;
          line-height: 36px;

          ${media.tablet`
            font-size: 36px;
            line-height: ${spacing[48]};
          `}
        }
        ${media.tablet`
          max-width: fit-content;
        `}
      }
    }
  }

  #financing-with-partner-logo-non-us {
    padding-bottom: 0;

    ${media.tablet`
      padding-bottom: ${spacing[40]};
    `}
  }

  ul[role="tablist"] {
    li[aria-selected="true"] {
      border-color: ${red[50]};
    }
  }

  #financing-citizens-pay-5050-easy-financing,
  #financing-affirm-5050-how-to-pay {
    p {
      color: ${grey[90]}
    }
  }

  #financing-citizens-pay-card-group-3-items,
  #financing-affirm-card-group-3-items {
    padding-bottom: ${spacing[24]}

    div.citizens-financing-widget {
        padding: 0;
          a {
            color: black;
          }
      }
  }
  #financing-dual-partner-hero {
    div > div {
      div[class^='GenericHero'] {
        background: #181A1D54;
      }
    }

    section {
      h1, p {
        text-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
      }

      h1 {
        margin-bottom: ${spacing[16]} !important;
      }
    }
  }
  #financing-citizens-pay-card-group-2-items,
  #financing-affirm-card-group-2-items {
    padding-top: 0;

    & > .citizens-financing-widget {
      padding: 0;
        a {
          color: black;
        }
    }
  }

div[data-test-id="product-card-container"]  {
  .linkTextJifiti {
    font-weight: 400 !important;
  }
}

div[id="Value Calculator (Financing)"] {
  background-color: ${white};
}
`;

export default financingPageStyles;
