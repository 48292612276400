import { BreakpointWidths } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const deltaStyleOverrides = createGlobalStyle`
  @media (min-width: ${BreakpointWidths.desktop}px) {
    #deltalogos,
    #deltainstantaccess5050 > div,
    #deltainflightclass5050 > div {
      padding-bottom: 40px;
    }
  }
`;
export default deltaStyleOverrides;
