import { useRouter } from 'next/router';
import React from 'react';
import { useTracking } from 'react-tracking';
import {
  FULL_PAGE_EXPERIMENT_DATA,
  IS_FULL_PAGE_EXPERIMENT_FLAG,
  FULL_PAGE_EXPERIMENT_CANONICAL_SLUG,
} from './constants';
import { prospectsExperimentPaths } from './experiments/prospectsExperiments';
import { wwwExperimentPaths } from './experiments/wwwExperiments';

declare global {
  interface Window {
    [FULL_PAGE_EXPERIMENT_DATA]?: Record<string, any>;
    [IS_FULL_PAGE_EXPERIMENT_FLAG]?: boolean;
    [FULL_PAGE_EXPERIMENT_CANONICAL_SLUG]?: string;
  }
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
}

type AppType = 'www' | 'prospects';

export const useFullPageExperimentViewedLog = (
  appType: AppType,
  skipTracking = false,
) => {
  const { trackEvent } = useTracking();
  const isFullPageExperiment = useIsNextFullPageExperiment(appType);

  React.useEffect(() => {
    const parseExperimentData = () => {
      try {
        const cookieExperimentData = getCookie(FULL_PAGE_EXPERIMENT_DATA) || '';
        return (
          window[FULL_PAGE_EXPERIMENT_DATA] ||
          JSON.parse(decodeURIComponent(cookieExperimentData))
        );
      } catch (err) {
        return null;
      }
    };

    const fullPageExperimentData = parseExperimentData();

    if (isFullPageExperiment && fullPageExperimentData) {
      delete window[FULL_PAGE_EXPERIMENT_DATA];

      if (!skipTracking) {
        trackEvent({
          event: 'Experiment Viewed',
          properties: fullPageExperimentData,
        });
      }
    }
  }, [trackEvent, isFullPageExperiment, skipTracking]);
};

const useNextAsPathWithoutQuery = () => {
  const { asPath } = useRouter();

  // The URL constructor needs a "base" argument when you pass it just a path, but we don't care about the host in this case
  const parsedUrl = new URL(asPath, 'https://onepeloton.com');
  return parsedUrl.pathname;
};

export const useIsNextFullPageExperiment = (appType: AppType) => {
  // Checks if the path is a valid experiment path listed for the app.
  // Any query parameters used for testing variations (ex: ?optimizely_x=variation_1) should be ignored in the check.
  const routerAsPath = useNextAsPathWithoutQuery();

  return Object.values(
    appType === 'www' ? wwwExperimentPaths : prospectsExperimentPaths,
  ).includes(routerAsPath);
};

export const isFullPageExperiment = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return Boolean(window[IS_FULL_PAGE_EXPERIMENT_FLAG]);
};

export const fullPageExperimentCanonicalSlug = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return window[FULL_PAGE_EXPERIMENT_CANONICAL_SLUG];
};
