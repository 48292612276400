export const CONTROL = 'Original';
export const VARIATION_1 = 'Variation_1';
export const IS_FULL_PAGE_EXPERIMENT_FLAG = '__peloton_is_full_page_experiment';
export const FULL_PAGE_EXPERIMENT_DATA = '__peloton_full_page_experiment_data';
export const FULL_PAGE_EXPERIMENT_CANONICAL_SLUG =
  '__peloton_full_page_experiment_canonical_slug';
export const VISITOR_COOKIE_NAME = 'peloton_visitor_id';
export const NEW_SESSION_COOKIE_NAME = 'peloton_new_session';
export const FORCE_VARIATION_QUERY_PARAM = 'optimizely_x';
export const OPTIMIZELY_DISABLE_QUERY_PARAM = 'optimizely_disable';
export const PRIVATE_UAT_COOKIE_PREFIX = 'private_uat';
export const DD_USER_AGENT_FLAG = 'DatadogSynthetics';
export const FULL_PAGE_EXPERIMENT_PATH = '/.netlify/functions/full-page-experiment';
export const TWO_HOURS_IN_SECONDS = 2 * 60 * 60;
