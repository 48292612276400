import { BreakpointWidths, white } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const appPageStyles = createGlobalStyle`
    #app-subscriptions-section,
    #app-subscriptions-section-remove-interstitial,
    #app-subscriptions-section-variation,
    #app-subscriptions-section-5050-at,
    #app-subscriptions-section---app-test-cta {
      & > div > div {
          @media (max-width: ${BreakpointWidths.desktop - 1}px) {
            flex-direction: column-reverse;
          }
      }
      section {
          @media (max-width: ${BreakpointWidths.desktop - 1}px) {
            text-align: center;
          }
          & div[data-test-id="LinkButton Container"] > div {
            width: 100%
          }
      }
      video {
        object-fit: contain;
        vertical-align: middle;
        max-height: 593px;
      }
    }
    #personalized-app-class-recommendations-quiz,
    #preview-classes-carousel,
    #preview-classes-carousel---app-cta-test {
      background-color: ${white};
    }
    [data-test-id="product-card-container"] {
      grid-template-rows: max-content auto;
    }
`;
export default appPageStyles;
