import { createGlobalStyle } from 'styled-components';

const appLuluPageStyles = createGlobalStyle`
    #app-lulu-5050-app-module {
      video {
        object-fit: contain;
        vertical-align: middle;
        max-height: 593px;
      }
    }
`;
export default appLuluPageStyles;
