import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { getTemplateSlug } from '@peloton/next/data/fetchTemplates';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import type { TypePage } from '@page-builder/lib/types';
import { toPageFetcherWithoutCache } from '../pageFetcherWithoutCache';
import { IMMUTABLE, REVALIDATE_DEFAULT } from '../revalidation';
import useNextPath from './useNextPath';

// Handles the index page as well as other routes
const trimLeadingSlash = (s: string) =>
  s.length > 1 && s.charAt(0) === '/' ? s.substring(1) : s;

const useNextPage = (
  fallback: TypePage | null,
  shouldUseFallbackSlugForPath: boolean = false,
) => {
  const nextPath = useNextPath();
  const locale = useLocale();

  const path = shouldUseFallbackSlugForPath && fallback ? fallback.fields.slug : nextPath;

  const slug = getTemplateSlug(trimLeadingSlash(path));

  const previewCopy = useIsPreviewCopyEnabled();
  const fetcher = toPageFetcherWithoutCache(slug, locale, previewCopy);

  // Only fetch from Contentful if previewCopy is enabled.
  const revalidation = previewCopy ? REVALIDATE_DEFAULT : IMMUTABLE;
  const swrKey = previewCopy ? `preview-${slug}` : slug;

  const { data } = useSWR(swrKey, fetcher, {
    ...revalidation,
    // Apply fallbackData if previewCopy is disabled to ensure we never fetch on prod
    fallbackData: previewCopy ? undefined : fallback,
  });

  // If preview copy is enabled
  const page = previewCopy && data ? data : fallback;

  return page;
};

export default useNextPage;
