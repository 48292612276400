import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import { useTracking } from 'react-tracking';
import { ALLOW_COPY_XRAY } from '@peloton/app-config';
import type { MetaDataProps } from '@peloton/next/components/MetadataTags';
import { NextMetadataTags } from '@peloton/next/components/MetadataTags';
import { PageFreezeWrapper } from '@peloton/next/components/PageFreezeWrapper';
import { combineMetaData } from '@peloton/next/data/customMetaData';
import type { MixedData } from '@peloton/next/data/fetchMixedData';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { Main, SkipLinkToMain } from '@acme-ui/global/skip-links';
import Nav from '@acme-ui/nav/Nav';
import useNextPage from '@content/client/hooks/useNextPage';
import CartPanel from '@ecomm/cart-next/panel/Panel';
import { useIsNavRefactorEnabled } from '@ecomm/feature-toggle/NavRefactor';
import {
  useFullPageExperimentViewedLog,
  useIsNextFullPageExperiment,
} from '@ecomm/full-page-experiments/browser';
import PbxNav from '@ecomm/nav/Nav';
import useCustomStyle from '@page-builder/hooks/useCustomStyle';
import { PageContentTypes } from '@page-builder/lib/constants';
import type { TypePage } from '@page-builder/lib/types';
import BannerOrVariation from '@page-builder/modules/Banner/BannerOrVariation';
import BlockProvider from '@page-builder/modules/Block/BlockProvider';
import NextBlock from '@page-builder/modules/Block/NextBlock';
import { toBannerProps, toPageData } from '@page-builder/utils/pages';

const Footer = dynamic(() => import('@acme-ui/footer'), {
  ssr: false, // Needs access to window for one trust
});
const CopyXray = dynamic(() => import('@ecomm/copy-xray/CopyXray'), {
  ssr: false,
});

export const isHomepageSlug = (slug: string = '') => decodeURIComponent(slug) === '/';

const PageComponents = {
  [PageContentTypes.MarketingPage]: dynamic(
    () => import('@page-builder/layout/MarketingPage'),
  ),
  [PageContentTypes.LegalPage]: dynamic(
    () => import('@page-builder/layout/MarkdownPage/MarkdownPage'),
  ),
  [PageContentTypes.EcommPage]: dynamic(
    () => import('@page-builder/layout/NextEcommPage'),
  ),
  [PageContentTypes.CheckoutPage]: dynamic(
    () => import('@page-builder/layout/OneStepCheckoutPage'),
    {
      ssr: false,
    },
  ),
  [PageContentTypes.InstructorMatchResultsPage]: dynamic(
    () => import('@page-builder/layout/InstructorMatchResultsPage'),
  ),
};

type PageProps = {
  page: TypePage;
  localizedPageSlugs: MixedData['localizedPageSlugs'];
  customMetaData?: MetaDataProps;
  transparent?: boolean;
};

export const NextPageBuilderPage: NextPage<PageProps> = ({
  page: fallbackPage,
  localizedPageSlugs,
  transparent = false,
  customMetaData,
}) => {
  useFullPageExperimentViewedLog('www');

  const locale = useLocale();
  const router = useRouter();
  const isFullPageExperiment = useIsNextFullPageExperiment('www');
  const isNavRefactorEnabled = useIsNavRefactorEnabled();
  const page = useNextPage(fallbackPage, isFullPageExperiment);
  const pageData = toPageData(page);
  const { pageTypeId, slug, id, pageContent } = pageData;
  const metaData = combineMetaData({
    locale,
    pageData,
    customMetaData,
    localizedPageSlugs,
    metaDataOverrides: {
      slug: router.asPath,
    },
  });

  const PageComponent = PageComponents[pageTypeId];
  const StyleOverrides = useCustomStyle(slug);
  const { Track } = useTracking({
    parent: pageTypeId,
  });

  if (!PageComponent) {
    console.warn(`${pageTypeId} can not be handled`);
    return null;
  }

  const isTransparentNav = isHomepageSlug(slug);

  const { banner: bannerProps, hideBanner } = toBannerProps(page);

  /**
   * Legal pages component
   * */
  if (pageTypeId === PageContentTypes.LegalPage) {
    return (
      <>
        <NextMetadataTags {...metaData} />
        <div data-test-id="legal-page">
          <Track>
            {ALLOW_COPY_XRAY && <CopyXray />}
            <StyleOverrides />
            {!hideBanner && <BannerOrVariation data={bannerProps} />}
            <SkipLinkToMain />
            {isNavRefactorEnabled ? <PbxNav /> : <Nav transparent={transparent} />}
            <Main>
              <PageComponent key={`${pageTypeId}-${id}`} {...pageContent.fields} />
            </Main>
            <CartPanel />
            <Footer locale={locale} />
          </Track>
        </div>
      </>
    );
  }

  /**
   * One Step Checkout Page component
   * */

  if (pageTypeId === PageContentTypes.CheckoutPage) {
    return (
      <>
        <NextMetadataTags {...metaData} />
        <Track>
          {ALLOW_COPY_XRAY && <CopyXray />}
          <StyleOverrides />
          <Main>
            <BlockProvider>
              <PageComponent
                key={`${pageTypeId}-${id}`}
                {...pageContent.fields}
                block={NextBlock}
              />
            </BlockProvider>
          </Main>
        </Track>
      </>
    );
  }

  /**
   * Non-legal and non-checkout pages component
   * */
  return (
    <>
      <NextMetadataTags {...metaData} />
      <Track>
        {ALLOW_COPY_XRAY && <CopyXray />}
        <StyleOverrides />
        {!hideBanner && <BannerOrVariation data={bannerProps} />}
        <SkipLinkToMain />
        <PageFreezeWrapper overrideFreeze>
          {isNavRefactorEnabled ? (
            <PbxNav />
          ) : (
            <Nav transparent={isTransparentNav ? true : transparent} sticky />
          )}
          <Main>
            <BlockProvider>
              <PageComponent
                key={`${pageTypeId}-${id}`}
                {...pageContent.fields}
                block={NextBlock}
              />
            </BlockProvider>
          </Main>
          <CartPanel />
          <Footer locale={locale} />
        </PageFreezeWrapper>
      </Track>
    </>
  );
};
