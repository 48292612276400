import { grey, white } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const rowPlpStyles = createGlobalStyle`
  #videoTrailersSection {
    background: ${grey[90]};
    color: ${white};
  }
`;

export default rowPlpStyles;
