import { black, BreakpointWidths } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';
import {
  HEADBAND_HEIGHT_MOBILE,
  HEADBAND_HEIGHT_TABLET_XLARGE,
} from '@ecomm/header/constants';
import { ComponentContentTypes } from '@page-builder/lib/constants';

const bikeRentalsStyleOverrides = createGlobalStyle`
    #clubvaluepropscolumns  p,
    #colorado-column  p,
    #florida-column  p,
    #georgia-column  p,
    #illinois-column  p,
    #maryland-column  p,
    #massachusetts-column  p,
    #minnesota-column  p,
    #texas-column  p,
    #virginia-column  p {
        color: ${black};
    }

    .first-block-offset:not(.Fullwidth_Media, .Fullwidth_Lightbox, .${ComponentContentTypes.Hero}) {
        &:before {
            content: '';
            display: block;
            height: ${HEADBAND_HEIGHT_MOBILE}px;
        }
        &.${ComponentContentTypes.ContainedMedia}, &.${ComponentContentTypes.ImageCards} {
            flex-flow: column;
            align-items: center;
        }

        @media (min-width: ${BreakpointWidths.tablet}px) {
            &:before {
                content: '';
                display: block;
                height: ${HEADBAND_HEIGHT_TABLET_XLARGE}px;
            }
        }
    }

`;
export default bikeRentalsStyleOverrides;
