import type { createGlobalStyle } from 'styled-components';
import rowPlpStyles from '@page-builder/layout/custom-styles/rowPlpStyles';
import usStatesPrivacyNoticeStyles from '@page-builder/layout/custom-styles/usStatesPrivacyNotice';
import appLuluPageStyles from '../layout/custom-styles/appLuluPageStyles';
import appPageStyles from '../layout/custom-styles/appPageStyles';
import bikeRentals from '../layout/custom-styles/bikeRentals';
import classDisciplinePageStyles from '../layout/custom-styles/classDisciplinePageStyles';
import classesPageStyles from '../layout/custom-styles/classesPageStyles';
import dealsPageStyles from '../layout/custom-styles/dealsPageStyles';
import delta from '../layout/custom-styles/delta';
import financingPageStyles from '../layout/custom-styles/financingPageStyles';
import giftCardsPageStyles from '../layout/custom-styles/giftCards';
import guidePageStyles from '../layout/custom-styles/guidePageStyles';
import instructorsPageStyles from '../layout/custom-styles/instructorsPageStyles';
import newWayIn from '../layout/custom-styles/newWayIn';

type GlobalStyle = ReturnType<typeof createGlobalStyle>;
type CustomStyles = Record<string, GlobalStyle>;

// key has to match slug specified in PB Compose Page
const styleOverrides: CustomStyles = {
  delta,
  ['new-way-in']: newWayIn,
  ['app']: appPageStyles,
  ['app-membership-lululemon']: appLuluPageStyles,
  ['bike/rentals']: bikeRentals,
  ['guide']: guidePageStyles,
  ['row']: rowPlpStyles,
  ['us-states-privacy-notice']: usStatesPrivacyNoticeStyles,
  ['instructors-test']: instructorsPageStyles,
  ['instructors-test/bike']: instructorsPageStyles,
  ['instructors-test/yoga']: instructorsPageStyles,
  ['instructors-test/tread']: instructorsPageStyles,
  ['classes/yoga']: classDisciplinePageStyles,
  ['classes/meditation']: classDisciplinePageStyles,
  ['classes/cardio']: classDisciplinePageStyles,
  ['classes/stretching']: classDisciplinePageStyles,
  ['classes/cycling']: classDisciplinePageStyles,
  ['classes/rowing']: classDisciplinePageStyles,
  ['classes/running']: classDisciplinePageStyles,
  ['classes/walking']: classDisciplinePageStyles,
  ['classes/strength']: classDisciplinePageStyles,
  ['classes/outdoor-workout']: classDisciplinePageStyles,
  ['financing']: financingPageStyles,
  ['financing/citizens-pay']: financingPageStyles,
  ['financing/affirm']: financingPageStyles,
  ['deals']: dealsPageStyles,
  ['classes']: classesPageStyles,
  ['gift-cards']: giftCardsPageStyles,
  // willl remove this one after testing
  ['page-builder-plus/classes']: classesPageStyles,
};

const useCustomStyle = (slug: string) => styleOverrides[slug] ?? (() => null);

export default useCustomStyle;
