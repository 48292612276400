import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { Locale } from '@peloton/internationalize';
import { AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE } from '@peloton/internationalize';
import type { Class } from '@peloton/models/PelotonClass';
import type { MetaDataProps } from '@peloton/next/components/MetadataTags';
import { useLocale } from '@peloton/next/hooks/useLocale';
import {
  CLASS_ID_PAGES_REGEX,
  CLASS_LISTING_PAGES_REGEX,
  FREE_CLASS_VIDEO_PAGES_REGEX,
} from '@ecomm/classes/models/constants';
import { CLASS_PAGE_REGEX } from '@ecomm/classes/models/disciplines';
import { getClassListingPageSegments } from '@ecomm/classes/utils/getClassListingPageSegments';
import { transformClassMetaData } from '@ecomm/classes/utils/transformClassMetaData';
import { transformFreeClassMetaData } from '@ecomm/classes/utils/transformFreeClassMetaData';
import { toFilteredPageSeoDescription } from '@page-builder/modules/ClassesFilters/helpers/toFilteredPageSeoDescription';
import { toFilteredPageSeoTitle } from '@page-builder/modules/ClassesFilters/helpers/toFilteredPageSeoTitle';
import { useRawFiltersData } from '@page-builder/modules/ClassesFilters/hooks/useClassFilters';
import { useFilteredPageLabels } from '@page-builder/modules/ClassesFilters/hooks/useFilteredPageLabels';
import type { PageData } from '@page-builder/utils/pages';
import getImageUrlFromFirstChildClass from '../utils/getImageUrlFromFirstChildClass';
import { transformPaginatedPath } from '../utils/pagination';
import type { MixedData } from './fetchMixedData';
import type { PagePropsContext } from './toPagePropsContext';

export type Transformer = (customPageData: any, pageData?: any) => MetaDataProps;
export type CustomMetaData = MetaDataProps | null;

export type ToMetaDataParams = {
  context: PagePropsContext;
  mixedData: MixedData;
  additionalProps: object;
  query?: NextParsedUrlQuery;
};

export const toProspectsMetaData = ({
  context,
  mixedData,
}: ToMetaDataParams): Partial<CustomMetaData> => {
  if (CLASS_ID_PAGES_REGEX.test(context.pageSlugWithBasePath)) {
    const { fitnessDiscipline, slug } = mixedData.customPageData as Class;
    return transformClassMetaData(
      mixedData.customPageData as Class,
      mixedData.page,
      `/${context.basePath}/${fitnessDiscipline}/${slug}`,
      context.locale,
    );
  }
  if (CLASS_LISTING_PAGES_REGEX.test(context.pageSlugWithBasePath)) {
    return {
      slug: transformPaginatedPath(context.pageSlugWithBasePath, true),
      imageUrl: getImageUrlFromFirstChildClass(mixedData.customPageData),
    };
  }

  if (FREE_CLASS_VIDEO_PAGES_REGEX.test(context.pageSlugWithBasePath)) {
    return transformFreeClassMetaData(
      mixedData.customPageData as Class,
      context.pageSlugWithBasePath,
      context.locale,
    );
  }

  if (CLASS_PAGE_REGEX.test(context.pageSlugWithBasePath)) {
    return transformClassMetaData(
      mixedData.customPageData as Class,
      mixedData.page,
      context.pageSlugWithBasePath,
      context.locale,
    );
  }

  return null;
};

export const useMetaDataOverrides = (): MetaDataParams['metaDataOverrides'] => {
  const router = useRouter();
  const locale = useLocale();
  const { noIndex } = useRawFiltersData();
  const { isDiscipline, isFiltered } = getClassListingPageSegments(router.asPath);
  const filteredPageLabels = useFilteredPageLabels();

  if (isDiscipline && isFiltered) {
    return {
      title: toFilteredPageSeoTitle({
        filteredPageLabels,
        locale,
      }),
      description: toFilteredPageSeoDescription({
        filteredPageLabels,
        locale,
      }),
      noIndex,
    };
  }

  return {};
};

const DEFAULT_TITLE =
  'Peloton® | Exercise Bike With Indoor Cycling Classes Streamed Live & On-Demand';

type MetaDataParams = {
  locale: Locale;
  pageData: PageData;
  localizedPageSlugs?: Partial<Record<Locale, string>> | null;
  customMetaData?: MetaDataProps;
  metaDataOverrides?: Partial<MetaDataProps>;
};

export const combineMetaData = ({
  locale,
  pageData,
  localizedPageSlugs,
  customMetaData,
  metaDataOverrides,
}: MetaDataParams): MetaDataProps => {
  const imageUrl = metaDataOverrides?.imageUrl || customMetaData?.imageUrl;
  const customImage = imageUrl
    ? toCloudinarySrc({ src: imageUrl, width: 400 })
    : undefined;

  const availableHrefLangs = localizedPageSlugs
    ? (Object.keys(localizedPageSlugs) as Partial<Locale>[])
    : AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE;

  return {
    title:
      metaDataOverrides?.title ||
      customMetaData?.title ||
      pageData.title ||
      DEFAULT_TITLE,
    keywords:
      metaDataOverrides?.keywords || customMetaData?.keywords || pageData.keywords || [],
    description:
      metaDataOverrides?.description ||
      customMetaData?.description ||
      pageData.description ||
      '',
    locale,
    slug: metaDataOverrides?.slug || customMetaData?.slug || pageData.slug,
    imageUrl: customImage || pageData.imageUrl,
    noIndex: metaDataOverrides?.noIndex ?? pageData.noIndex,
    localizedPageSlugs,
    availableHrefLangs,
  };
};

export const combineProspectsMetaData = ({
  locale,
  pageData,
  customMetaData,
  metaDataOverrides,
}: MetaDataParams): MetaDataProps => {
  const imageUrl = metaDataOverrides?.imageUrl || customMetaData?.imageUrl;
  const customDpr = 2.0;
  const customImage = imageUrl
    ? toCloudinarySrc({
        src: imageUrl,
        devicePixelRatio: customDpr,
        width: 400,
      })
    : undefined;

  return {
    title:
      metaDataOverrides?.title ||
      customMetaData?.title ||
      pageData.title ||
      DEFAULT_TITLE,
    keywords:
      metaDataOverrides?.keywords || customMetaData?.keywords || pageData.keywords || [],
    description:
      metaDataOverrides?.description ||
      customMetaData?.description ||
      pageData.description ||
      '',
    locale,
    slug: metaDataOverrides?.slug || customMetaData?.slug || pageData.slug,
    imageUrl: customImage || pageData.imageUrl,
    noIndex: metaDataOverrides?.noIndex ?? pageData.noIndex,
  };
};
