import { BreakpointWidths, spacing } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const instructorsPageStyles = createGlobalStyle`
    #instructors-peloton {
      height: 300px;

      & > div[class*='Container__StyledContainer'] {
        height: 100%;
      }

      & > section[class*='Layout__StyledLayout'] {
        min-height: 0;
        height: 100%;
      }

      section > section {
        width: 100%;
          div > div > div {
            max-width: 100%;
          } 
        }

        @media (min-width: ${BreakpointWidths.tablet}px) {
          height: 527px;
        }

        @media (min-width: ${BreakpointWidths.desktop}px) {
          height: 577px;
        }
      }
      
    #instructors-peloton h1 {
      letter-spacing: 0px;
      line-height: 1.375;
      font-size: 28px;
  
      @media (min-width: ${BreakpointWidths.tablet}px) {
        font-size: ${spacing[40]};
      }
  
      @media (min-width: ${BreakpointWidths.desktop}px) {
        font-size: 44px;
        line-height: 1.25;
      }
    }

    div[class*='PromoWrapper'] {
      padding: 30px 20px 0px;

      @media (min-width: ${BreakpointWidths.tablet}px) {
        padding: 25px 20px 0px;
      }

      @media (min-width: ${BreakpointWidths.desktop}px) {
        padding: ${spacing[40]} 50px 0px;
      }

    }
  `;
export default instructorsPageStyles;
