import { brand, grey, white } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const usStatesPrivacyNoticeStyles = createGlobalStyle`
  table {
    margin-bottom: 20px;
    text-align: left;

    &,
    th,
    td {
      border: 1px solid ${brand.darkest};
    }

    th,
    td {
      padding: 10px 15px;
    }

    th {
      color: ${white};
      font-weight: bold;
    },

    tr {
      td:first-child {
        width: 30%;
      }
    }
  }

  thead {
    background-color: ${grey[70]};
  }

  h2 ~ ul {
    list-style: initial;
    margin-left: 2em;
  }
`;

export default usStatesPrivacyNoticeStyles;
