import {
  BreakpointWidths,
  secondaryFamily,
  spacing,
  black,
  grey,
} from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const guidePageStyles = createGlobalStyle`
  #guide-membership h1 {
    font-family: ${secondaryFamily};
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 2.6px;
    line-height: 1.25;
    text-shadow: ${spacing[2]} ${spacing[2]} 6px ${black};

    @media (min-width: ${BreakpointWidths.tablet}px) {
      font-size: ${spacing[40]};
      line-height: 1.1;
    }

    @media (min-width: ${BreakpointWidths.desktop}px) {
      font-size: ${spacing[48]};
      letter-spacing: 4.8px;
    }
  }

  #guide-membership h2 {
    font-size: 14px;
    line-height: 1.42;
    letter-spacing: 1.5px;

    @media (min-width: ${BreakpointWidths.tablet}px) {
      font-size: ${spacing[16]};
    }
  }

  #classesTeasersSection {
    h2 {
      color: ${grey[90]};
    }
  }
`;
export default guidePageStyles;
