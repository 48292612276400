import type { FC } from 'react';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { GlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';

// Prevent scrolling background when MegaMenu is open.
export const PageFreezeWrapper: FC<
  React.PropsWithChildren<{ overrideFreeze: boolean; children: any }>
> = ({ overrideFreeze, children }) => {
  const { freeze } = useContext(GlobalUiStateContext);

  return (
    <FreezeWrapper
      freezeBackground={freeze && !overrideFreeze}
      data-test-id="page-freeze-wrapper"
    >
      {children}
    </FreezeWrapper>
  );
};

const FreezeWrapper = styled.div<{ freezeBackground: boolean }>(
  ({ freezeBackground }) => css`
    ${freezeBackground &&
    css`
      position: fixed;
      left: 0;
      right: 0;
    `}
  `,
);
