import { BreakpointWidths, grey } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

const classDisciplinePageStyles = createGlobalStyle`
  #classes-grid {
    box-shadow: 0 -1px ${grey[90]}, 0 1px ${grey[90]};
  }

  #start-free-trial > div {
    background-size: contain;
    background-position-y: unset;
  }

  #start-free-trial > div > div {
    max-width: 260px;
    justify-content: center;

    @media (min-width: ${BreakpointWidths.tablet}px) {
      max-width: 680px;
    }
  }

    div[data-test-id="cta-banner-container"] a {
        white-space: nowrap;
    }
`;
export default classDisciplinePageStyles;
