import { Locale } from '../../../@peloton/internationalize/models/locale';
import { CONTROL, VARIATION_1 } from '../constants';
import type { FullPageExperiment } from '../models';

export const wwwExperimentPaths = {
  home: '/',
  app: '/app',
  bike: '/bike',
  shopBike: '/shop/bike/bike-package',
  shopBikeRefurb: '/shop/refurbished/bike',
  shopBikeOpc: '/shop/opc/bike',
  bikePlus: '/bike-plus',
  shopBikePlus: '/shop/bike-plus/bike-plus-basics-package-us',
  shopBikePlusRefurb: '/shop/refurbished/bike-plus',
  shopBikePlusOpc: '/shop/opc/bike-plus',
  tread: '/tread',
  shopTread: '/shop/tread/tread-basics-package-us',
  treadPlus: '/tread-plus',
  shopTreadPlus: '/shop/tread-plus/tread-plus-basics-package-us',
};

const COMBINED_BIKE_PDP_PLP_PATH = '/bike-combined-pdp-plp';
const COMBINED_BIKE_PLUS_PDP_PLP_PATH = '/bike-plus-combined-pdp-plp';
const COMBINED_TREAD_PDP_PLP_PATH = '/tread-combined-pdp-plp';
const COMBINED_TREAD_PLUS_PDP_PLP_PATH = '/tread-plus-combined-pdp-plp';

export const wwwExperiments: FullPageExperiment[] = [
  {
    path: wwwExperimentPaths.app,
    experimentKey: 'app_page_optimizations',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.app,
      },
      {
        variationKey: VARIATION_1,
        path: '/app-redesign',
      },
    ],
  },
  {
    path: wwwExperimentPaths.home,
    experimentKey: 'homepage_outcomes',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.home,
      },
      {
        variationKey: VARIATION_1,
        path: '/homepage-outcomes',
      },
    ],
  },
  {
    path: wwwExperimentPaths.bike,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.bike,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopBike,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopBike,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopBikeRefurb,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopBikeRefurb,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopBikeOpc,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopBikeOpc,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.bikePlus,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.bikePlus,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PLUS_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopBikePlus,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopBikePlus,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PLUS_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopBikePlusRefurb,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopBikePlusRefurb,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PLUS_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopBikePlusOpc,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopBikePlusOpc,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_BIKE_PLUS_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.tread,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.tread,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_TREAD_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopTread,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopTread,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_TREAD_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.treadPlus,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.treadPlus,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_TREAD_PLUS_PDP_PLP_PATH,
      },
    ],
  },
  {
    path: wwwExperimentPaths.shopTreadPlus,
    experimentKey: 'pdpOrganicSearch',
    locales: [Locale.EnglishUnitedStates],
    variations: [
      {
        variationKey: CONTROL,
        path: wwwExperimentPaths.shopTreadPlus,
      },
      {
        variationKey: VARIATION_1,
        path: COMBINED_TREAD_PLUS_PDP_PLP_PATH,
      },
    ],
  },
];
